import {
  ArrowRightIcon,
  CalendarIcon,
  CreditCardIcon,
  FileTextIcon,
  LinkIcon,
  RefreshCcwIcon,
  ChevronRightIcon,
  QrCodeIcon,
} from "lucide-react";
import React from "react";
export default function PaymentsCard() {
  return (
    <div className="w-full max-w-[888px] mx-auto p-6 font-sans text-sm text-[#454745]">
      <div className="flex flex-wrap gap-2">
        <button className="flex items-center px-3 py-2 text-[#163300] font-semibold bg-[#9fe870] rounded-full">
          Send
          <ArrowRightIcon className="w-4 h-4 ml-2" />
        </button>
        <button className="flex items-center px-3 py-2 text-[#163300] font-semibold bg-[rgba(22,51,0,0.08)] rounded-full">
          Request
          <ArrowRightIcon className="w-4 h-4 ml-2" />
        </button>
      </div>

      <div className="mt-10">
        <div className="mb-4 flex justify-between items-end flex-wrap">
          <h3 className="text-xl font-semibold text-[#0e0f0c]">Outgoing</h3>
        </div>

        <ul className="space-y-[-1px]">
          <li>
            <a
              href="/link?urn=urn%3Awise%3Atransfers%3Ascheduled"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <CalendarIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Scheduled transfers
                  </h4>
                  <p className="mt-1 text-sm">
                    Manage transfers that are due to go out.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>

          <li>
            <a
              href="/link?urn=urn%3Awise%3Adirect-debits"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <CreditCardIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Direct Debits
                  </h4>
                  <p className="mt-1 text-sm">
                    Set up and manage your authorised payments.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>

          <li>
            <a
              href="/link?urn=urn%3Awise%3Abatch-payments"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <FileTextIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Batch payments
                  </h4>
                  <p className="mt-1 text-sm">
                    Create and send multiple transfers with one payment file.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div className="mt-10">
        <div className="mb-4 flex justify-between items-end flex-wrap">
          <h3 className="text-xl font-semibold text-[#0e0f0c]">Incoming</h3>
        </div>

        <ul className="space-y-[-1px]">
          <li>
            <a
              href="/link?urn=urn%3Awise%3Ainvoices"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <FileTextIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">Invoices</h4>
                  <p className="mt-1 text-sm">
                    Create and manage your invoices.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>

          <li>
            <a
              href="/link?urn=urn%3Awise%3Arequests"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <LinkIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Payment links
                  </h4>
                  <p className="mt-1 text-sm">
                    Create and manage your links to accept one or multiple
                    payments.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>

          <li>
            <a
              href="/link?urn=urn%3Awise%3Aquickpay"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <QrCodeIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">Quick Pay</h4>
                  <p className="mt-1 text-sm">
                    Your unique link and QR code to get paid fast.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div className="mt-10">
        <div className="mb-4 flex justify-between items-end flex-wrap">
          <h3 className="text-xl font-semibold text-[#0e0f0c]">
            Account details
          </h3>
          <button className="px-4 py-1 text-[#163300] font-semibold underline underline-offset-4 rounded-full hover:bg-[rgba(22,51,0,0.08)]">
            See all
          </button>
        </div>

        <ul className="space-y-[-1px]">
          <li>
            <a
              href="/link?urn=urn%3Awise%3Abalances%3Adetails%3F%3Daction%3Dorder%26currency%3DAED"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 rounded-full overflow-hidden border border-[rgba(14,15,12,0.12)]">
                    <img
                      src="https://wise.com/web-art/assets/flags/aed.svg"
                      className="w-full h-full object-cover"
                      alt="AED"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    United Arab Emirates Dirham
                  </h4>
                  <p className="mt-1 text-sm">IBAN, Swift/BIC</p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>

          <li>
            <a
              href="/link?urn=urn%3Awise%3Abalances%3Adetails%3F%3Daction%3Dorder%26currency%3DEUR"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 rounded-full overflow-hidden border border-[rgba(14,15,12,0.12)]">
                    <img
                      src="https://wise.com/web-art/assets/flags/eur.svg"
                      className="w-full h-full object-cover"
                      alt="EUR"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">Euro</h4>
                  <p className="mt-1 text-sm">IBAN, Swift/BIC</p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div className="mt-10">
        <div className="mb-4 flex justify-between items-end flex-wrap">
          <h3 className="text-xl font-semibold text-[#0e0f0c]">
            Payment tools
          </h3>
        </div>

        <ul>
          <li>
            <a
              href="/link?urn=urn%3Awise%3Aauto-conversions"
              className="block p-4 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center">
                <div className="mr-4">
                  <div className="w-12 h-12 flex items-center justify-center bg-[rgba(22,51,0,0.08)] rounded-full">
                    <RefreshCcwIcon className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className="font-semibold text-[#0e0f0c]">
                    Auto Conversions
                  </h4>
                  <p className="mt-1 text-sm">
                    Convert money between your balances at your desired exchange
                    rate.
                  </p>
                </div>
                <ChevronRightIcon className="w-5 h-5 text-[#163300]" />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
