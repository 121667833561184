import React from "react";
import PaymentsCard from "./PaymentsCard";
const Payments = () => {
  return (
    <div className="main-content main-content--bricks">
      <PaymentsCard />
    </div>
  );
};

export default Payments;
