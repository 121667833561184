import { ChevronDownIcon, ActivityIcon, LockIcon } from "lucide-react";
import React from "react";
export default function WiseVirtualCard() {
  return (
    <div className="w-full max-w-[400px] mx-auto flex flex-col gap-4 text-[#454745] text-sm leading-[21.7px] font-sans p-4">
      <div className="flex flex-col max-h-[168px] transition-all duration-450 ease-out rounded-t-xl shadow-lg p-8">
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <div className="max-w-[208px] overflow-hidden text-ellipsis whitespace-nowrap text-[15px] leading-6">
              Nader's Debit Card
            </div>
            <div>
              <a
                href="/transactions?q=%7B%22filters%22%3A%7B%22date%22%3A%7B%22start%22%3A%2211-09-2024%22%2C%22end%22%3A%2211-09-2024%22%7D%2C%22debitCardIds%22%3A%5B%226927d8f6-6527-11ee-ae39-1f3d4d7d1bf7%22%5D%7D%7D"
                className="text-gray-900"
              >
                <div className="text-[15px] font-medium leading-6">$0.00</div>
              </a>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="relative">
              <div className="text-xs text-gray-600 leading-5 tracking-wide max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                <a
                  href="/settings/team/674bd40c-31cf-11ec-81f7-ebfb95bf11f9"
                  className="text-gray-600"
                >
                  Nader kutub
                </a>
              </div>
            </div>
            <div className="text-xs text-gray-600 leading-5 tracking-wide">
              Spent today
            </div>
          </div>

          <section className="flex flex-col">
            <div className="pt-2">
              <div className="relative h-4 w-full bg-gray-100 border border-gray-200 rounded shadow-inner">
                <div className="absolute top-[-2px] right-[305px] h-5 w-[1px] bg-gray-400"></div>
                <div className="absolute right-0 top-0 h-[14px] w-[305px] rounded-r"></div>
              </div>
            </div>

            <div className="flex justify-between items-center pt-2 cursor-pointer">
              <div className="text-xs text-gray-600 leading-5 tracking-wide flex items-center">
                <ChevronDownIcon className="h-3 w-3 mr-1 text-gray-400 transform -rotate-90" />
                Show details
              </div>
              <div className="text-xs text-gray-600 leading-5 tracking-wide">
                $101.00 available · $2.5K limit
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="max-h-[742px] rounded-b-xl">
        <div className="flex flex-col justify-between relative w-full rounded-b-xl">
          <div className="flex justify-center">
            <div>
              <div
                className="relative flex flex-col h-[200px] w-[320px] bg-contain bg-no-repeat rounded-xl p-4 shadow-lg"
                style={{
                  backgroundImage:
                    'url("https://cdn.mercury.com/builds/6492b0daa622f1d694e305dc226f913c3638ab03/icons/cards/revealable/debitVirtual.png")',
                }}
              >
                <div className="flex items-center">
                  <div className="h-2 w-2 rounded-full bg-green-600 mr-1.5"></div>
                  <span className="text-sm">Active</span>
                </div>

                <div className="absolute bottom-4 flex flex-col justify-center h-[42px]">
                  <div className="relative w-36 cursor-pointer">
                    <div className="relative w-32 pointer-events-none">
                      <span className="text-xs tracking-wide">
                        •••• •••• •••• 2020
                      </span>
                    </div>
                    <div className="flex mt-0.5">
                      <div className="flex items-center">
                        <span className="text-xs text-gray-500 mr-1.5">
                          EXP
                        </span>
                        <div className="relative w-11 pointer-events-none text-gray-900">
                          <span className="text-xs tracking-wide">••/••</span>
                        </div>
                      </div>
                      <div className="flex items-center ml-5">
                        <span className="text-xs text-gray-500 mr-1.5">
                          CVC
                        </span>
                        <div className="relative pointer-events-none">
                          <span className="text-xs tracking-wide text-gray-900">
                            •••
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center p-5 border-b border-gray-100">
            <button className="flex items-center text-gray-700">
              <LockIcon className="h-3 w-3 mr-1.5 text-gray-400" />
              <span className="text-[15px]">Freeze</span>
            </button>

            <button className="flex items-center text-gray-700">
              <ActivityIcon className="h-3 w-3 mr-1.5 text-gray-400" />
              <span className="text-[15px]">Transactions</span>
            </button>

            <div className="relative">
              <button className="flex items-center text-gray-700 px-3 py-1 rounded-2xl">
                <span className="text-[15px]">More</span>
                <ChevronDownIcon className="h-3 w-3 ml-1.5 text-gray-400" />
              </button>
            </div>
          </div>

          <div className="p-6 sm:p-10 border-b border-gray-100">
            <div>
              <div className="text-xs text-gray-600 tracking-wide mb-1">
                Billing address
              </div>
              <div className="relative max-w-[208px] min-w-[128px]">
                <div className="text-[15px] text-gray-900 cursor-pointer">
                  <p>39506 N Daisy Mountain Dr</p>
                  <p>Unit 208</p>
                  <p>Phoenix, AZ 85086</p>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4 sm:p-10">
            <div className="flex justify-between items-center mt-4">
              <p className="text-sm text-gray-700">Daily spend limit</p>
              <div className="flex items-center cursor-pointer">
                <p className="text-sm text-gray-900">$2,500.00</p>
              </div>
            </div>

            <div className="flex justify-between items-center mt-4">
              <p className="text-sm text-gray-700">Account</p>
              <a
                href="/accounts/depository/5a0b4858-6527-11ee-b7f5-5f0b1cdfe5c8"
                className="text-sm text-gray-900 hover:underline"
              >
                Checking ••2652
              </a>
            </div>

            <div className="flex justify-between items-center mt-4">
              <p className="text-sm text-gray-700">Card type</p>
              <p className="text-sm text-gray-900">Virtual Debit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
