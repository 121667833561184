import {
  HelpCircleIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  PlusIcon,
  ArrowUpRightIcon,
  UserIcon,
  RefreshCcwIcon,
  SendIcon,
} from "lucide-react";
import React from "react";
export default function WizeCard() {
  return (
    <div className="w-full max-w-[888px] mx-auto flex flex-col gap-4 text-[#454745] text-sm leading-[21.7px] font-sans p-4">
      <div className="mt-4">
        <div>
          <div className="text-sm leading-[21.7px] -tracking-[0.084px] font-semibold">
            Total balance
          </div>
          <div className="flex items-center gap-3">
            <h3 className="text-[#0e0f0c] leading-[27.5px] -tracking-[0.396px] text-[22px] font-semibold m-0">
              <span className="visible w-auto">0.00</span>
              USD
            </h3>
            <button
              type="button"
              aria-label="What is total balance?"
              className="text-[#454745] bg-transparent border-0 p-0"
            >
              <div className="relative select-none text-[#0e0f0c] min-w-6 w-6 h-6 text-xs rounded-full">
                <div className="flex items-center justify-center  text-[#0e0f0c] h-6 w-6 max-h-full max-w-full overflow-hidden rounded-full">
                  <span className="max-h-full max-w-full text-[#163300]">
                    <HelpCircleIcon className="h-6 w-6" />
                  </span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-4">
        <div className="flex flex-wrap justify-start gap-2 overflow-x-scroll scroll-smooth snap-x snap-mandatory scrollbar-none">
          <div className="flex flex-[0_0_auto]">
            <button
              type="button"
              aria-expanded="false"
              className="flex items-center justify-center h-8 px-3 bg-[#9fe870] text-[#163300] font-semibold rounded-full"
            >
              Send
              <span className="ml-2 block rotate-180 text-[#163300] pointer-events-none">
                <ArrowRightIcon className="h-4 w-4" />
              </span>
            </button>
          </div>

          <div className="flex flex-[0_0_auto]">
            <a
              href="/link?urn=urn%3Awise%3Abalances%3F%3Daction%3DtopUp"
              className="flex items-center justify-center h-8 px-3 bg-[rgba(22,51,0,0.08)] text-[#163300] font-semibold rounded-full"
            >
              Add money
            </a>
          </div>

          <div className="flex flex-[0_0_auto]">
            <button
              type="button"
              aria-expanded="false"
              className="flex items-center justify-center h-8 px-3 bg-[rgba(22,51,0,0.08)] text-[#163300] font-semibold rounded-full"
            >
              Request
              <span className="ml-2 block rotate-180 text-[#163300] pointer-events-none">
                <ArrowLeftIcon className="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-4">
        <div className="will-change-transform">
          <div className="relative">
            <div className="scroll-smooth snap-x snap-mandatory scroll-pl-8 scroll-pr-8 flex justify-start gap-3 overflow-x-auto scrollbar-none">
              {/* AED Balance Card */}
              <a
                aria-label="AED balance. 0.00 AED."
                href="/link?urn=urn%3Awise%3Abalances%3A97402948"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-[rgba(22,51,0,0.08)] text-[#163300] font-semibold rounded-2xl p-4 snap-center"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://wise.com/web-art/assets/flags/aed.svg"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)]"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    AED
                  </h4>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center"></div>
                  <div className="text-[#0e0f0c] leading-[27.5px] text-[22px] whitespace-nowrap mt-1">
                    <span className="visible w-auto">0.00</span>
                  </div>
                </div>
              </a>

              {/* EUR Balance Card */}
              <a
                aria-label="EUR balance. 0.00 EUR."
                href="/link?urn=urn%3Awise%3Abalances%3A97403187"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-[rgba(22,51,0,0.08)] text-[#163300] font-semibold rounded-2xl p-4 snap-center"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://wise.com/web-art/assets/flags/eur.svg"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)]"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    EUR
                  </h4>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center"></div>
                  <div className="text-[#0e0f0c] leading-[27.5px] text-[22px] whitespace-nowrap mt-1">
                    <span className="visible w-auto">0.00</span>
                  </div>
                </div>
              </a>

              {/* USD Balance Card */}
              <a
                aria-label="USD balance. 0.00 USD."
                href="/link?urn=urn%3Awise%3Abalances%3A97402004"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-[rgba(22,51,0,0.08)] text-[#163300] font-semibold rounded-2xl p-4 snap-center"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none min-w-12 w-12 h-12 rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <img
                          src="https://wise.com/web-art/assets/flags/usd.svg"
                          loading="lazy"
                          alt=""
                          width="64"
                          height="64"
                          className="h-12 w-12 object-cover rounded-full shadow-[0_0_0_1px_rgba(14,15,12,0.12)]"
                        />
                      </div>
                    </div>
                  </div>
                  <h4 className="text-[#454745] leading-[24.3px] -tracking-[0.252px] text-lg font-semibold">
                    USD
                  </h4>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center"></div>
                  <div className="text-[#0e0f0c] leading-[27.5px] text-[22px] whitespace-nowrap mt-1">
                    <span className="visible w-auto">0.00</span>
                  </div>
                </div>
              </a>

              {/* Add Balance Card */}
              <a
                aria-label="Open a balance."
                href="/link?urn=urn%3Awise%3Abalances%3F%3Daction%3Dopen"
                className="flex flex-col justify-between h-52 w-64 flex-[0_0_256px] bg-[rgba(22,51,0,0.02)] text-[#163300] font-semibold rounded-2xl p-4 snap-center border-2 border-dashed border-[#0e0f0c1f]"
              >
                <div className="flex items-center">
                  <div className="self-start mr-2">
                    <div className="relative select-none text-[#0e0f0c] min-w-12 w-12 h-12 text-2xl rounded-full">
                      <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                        <div className="text-[#0e0f0c]">
                          <PlusIcon className="h-6 w-6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-full text-left text-[#0e0f0c]">
                  <div className="text-sm leading-[21.7px] -tracking-[0.084px] flex items-center">
                    <span className="text-[#454745]">
                      Add another currency to your account.
                    </span>
                  </div>
                </div>
              </a>
            </div>

            <button
              type="button"
              tabIndex={-1}
              className="flex items-center justify-center h-12 w-12 bg-[#9fe870] text-[#163300] font-semibold rounded-full absolute top-[104px] right-4 -translate-y-6 opacity-0 transition-opacity duration-200"
            >
              <span className="block rotate-90 text-[#163300]">
                <ArrowUpRightIcon className="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col gap-4">
        <div className="flex items-center justify-between flex-row mt-4">
          <h3 className="text-[#0e0f0c] leading-[27.5px] -tracking-[0.396px] text-[22px] font-semibold m-0">
            Transactions
          </h3>
          <a
            href="/all-transactions"
            className="text-[#163300] font-semibold underline decoration-[#163300] relative -right-4 rounded"
          >
            <button
              type="button"
              className="leading-6 text-[#163300] h-8 -tracking-[0.154px] inline-block text-center align-middle whitespace-nowrap min-h-8 select-none bg-transparent transition-colors duration-150 underline decoration-[#163300] underline-offset-[4.2px] relative border-[0.75px] border-transparent rounded-full px-4 py-[3px]"
            >
              See all
            </button>
          </a>
        </div>

        <div>
          {/* Transaction Items */}
          <a
            role="button"
            href="/link?urn=urn%3Awise%3Aactivities%3ATU9ORVRBUllfQUNUSVZJVFk6OjI3MDMyNDUxOjpCQU5LX0RFVEFJTFNfT1JERVI6OjEyNzQ3Njk2"
            className="grid grid-cols-[48px_1fr_auto] gap-4 transition-colors duration-350 -mx-3  -tracking-[0.084px] rounded-lg p-4 text-[#0e0f0c]"
          >
            <div className="relative select-none min-w-12 w-12 h-12 text-2xl rounded-full">
              <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                <div className="text-[#6a6c6a]">
                  <UserIcon className="h-6 w-6" />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4 flex-1 pr-5">
              <div>
                <p className="text-base leading-6 -tracking-[0.176px] font-semibold m-0">
                  For{" "}
                  <strong className="text-[#6a6c6a]">
                    your account details
                  </strong>
                </p>
                <p className="text-sm leading-[21.7px] mt-1">Cancelled</p>
              </div>
            </div>

            <div className="flex items-center flex-[1_0_auto] self-start justify-end">
              <div className="text-right">
                <p className="text-base leading-6 -tracking-[0.176px] font-semibold m-0">
                  <span className="visible w-auto">31</span>
                  USD
                </p>
              </div>
            </div>
          </a>

          <a
            role="button"
            href="/link?urn=urn%3Awise%3Aactivities%3ATU9ORVRBUllfQUNUSVZJVFk6OjI3MDMyNDUxOjpUUkFOU0ZFUjo6MTE4MDY4MjEzNg%3D%3D"
            className="grid grid-cols-[48px_1fr_auto] gap-4 transition-colors duration-350 -mx-3  -tracking-[0.084px] rounded-lg p-4 text-[#0e0f0c]"
          >
            <div className="relative select-none min-w-12 w-12 h-12 text-2xl rounded-full">
              <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                <div className="text-[#6a6c6a]">
                  <RefreshCcwIcon className="h-6 w-6" />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4 flex-1 pr-5">
              <div>
                <p className="text-base leading-6 -tracking-[0.176px] font-semibold m-0">
                  To{" "}
                  <strong className="text-[#6a6c6a]">your USD balance</strong>
                </p>
                <p className="text-sm leading-[21.7px] mt-1">
                  Cancelled · Aug 26
                </p>
              </div>
            </div>

            <div className="flex items-center flex-[1_0_auto] self-start justify-end">
              <div className="text-right">
                <p className="text-base leading-6 -tracking-[0.176px] font-semibold m-0">
                  <span className="visible w-auto">100</span>
                  USD
                </p>
                <p className="text-sm leading-[21.7px] mt-1">
                  <span className="visible w-auto">102.27</span>
                  USD
                </p>
              </div>
            </div>
          </a>

          <a
            role="button"
            href="/link?urn=urn%3Awise%3Aactivities%3ATU9ORVRBUllfQUNUSVZJVFk6OjI3MDMyNDUxOjpUUkFOU0ZFUjo6MTE4MDY3NjU4Nw%3D%3D"
            className="grid grid-cols-[48px_1fr_auto] gap-4 transition-colors duration-350 -mx-3  -tracking-[0.084px] rounded-lg p-4 text-[#0e0f0c]"
          >
            <div className="relative select-none min-w-12 w-12 h-12 text-2xl rounded-full">
              <div className="flex items-center justify-center bg-[rgba(22,51,0,0.08)] h-12 w-12 overflow-hidden rounded-full">
                <div className="text-[#6a6c6a]">
                  <SendIcon className="h-6 w-6" />
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4 flex-1 pr-5">
              <div>
                <p className="text-base leading-6 -tracking-[0.176px] font-semibold m-0">
                  To{" "}
                  <strong className="text-[#6a6c6a]">your USD balance</strong>
                </p>
                <p className="text-sm leading-[21.7px] mt-1">
                  Cancelled · Aug 13
                </p>
              </div>
            </div>

            <div className="flex items-center flex-[1_0_auto] self-start justify-end">
              <div className="text-right">
                <p className="text-base leading-6 -tracking-[0.176px] font-semibold m-0">
                  <span className="visible w-auto">100</span>
                  USD
                </p>
                <p className="text-sm leading-[21.7px] mt-1">
                  <span className="visible w-auto">102.27</span>
                  USD
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
