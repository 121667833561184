/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_KYC_LINK } from "../../../../queries/user/queries";
import WelcomeCard from "./WelcomeCard";
import WizeCard from "./WizeCard";

const HomeSeller = ({ firstName, kycVerificationStatus, approvedSeller }) => {
  const [error, setError] = useState(undefined);

  const [startKycVerification, { loading }] = useLazyQuery(GET_KYC_LINK, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ startKycVerification: { link } }) => {
      window.location.href = link;
    },
    onError: (error) => {
      setError(JSON.stringify(error));
    },
  });

  const onPersonalIdentity = useCallback(() => {
    startKycVerification();
  }, []);

  return (
    <div className="main-content main-content--bricks">
      <WizeCard />
    </div>
  );
};

export default HomeSeller;
