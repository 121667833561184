// import React, { useEffect } from "react";
// import WithBusinesses from "../../WithBusinesses";
// import { useLazyQuery } from "@apollo/client";
// import { GET_VIRTUAL_CARDS } from "../../../queries/virtualCards";
import WiseVirtualCardList from "./WiseVirtualCardList";
export default function WithVirtualCards() {
  // export default function WithVirtualCards({ children }) {
  // const [
  //   getAllVirtualCards,
  //   { loading: loading, data: { virtualCards = [] } = {} },
  // ] = useLazyQuery(GET_VIRTUAL_CARDS);

  // useEffect(() => {
  //   getAllVirtualCards();
  // }, [getAllVirtualCards]);

  return (
    // <WithBusinesses>
    //   {({ businesses = [] }) =>
    //     businesses && businesses.length > 0 ? (
    //       children({
    //         loading,
    //         virtualCards,
    //         getAllVirtualCards,
    //         businesses,
    //       })
    //     ) : (
    //       <div>Business not approved error</div>
    //     )
    //   }
    // </WithBusinesses>
    <WiseVirtualCardList />
  );
}
