import { CreditCardIcon, FilterIcon, LockIcon } from "lucide-react";
import React from "react";
import WiseVirtualCard from "./WiseVirtualCard";
export default function WiseVirtualCardList() {
  return (
    <div className="w-full max-w-[888px] mx-auto flex flex-col gap-4 text-[#454745] text-sm leading-[21.7px] font-sans p-4">
      <div className="max-w-[888px]">
        <div className="flex items-center gap-3 mb-4">
          <h1 className="text-2xl font-medium">Cards</h1>

          <a
            href="/issue-card"
            className="inline-flex items-center px-4 py-1 text-sm rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700"
          >
            <CreditCardIcon className="w-4 h-4 mr-2" />
            Create Card
          </a>
        </div>

        <div className="border-t border-gray-100">
          <div className="flex gap-6">
            <div className="border-b-2 border-blue-600 text-gray-700 py-2.5 px-1">
              Manage
            </div>
            <div className="text-gray-500 py-2.5 px-1 cursor-pointer">
              Subscriptions
            </div>
          </div>
        </div>

        <div className="flex items-end gap-4 py-6">
          <button className="inline-flex items-center px-4 py-1.5 text-sm rounded-full bg-gray-100 hover:bg-gray-200 text-gray-700">
            <FilterIcon className="w-4 h-4 mr-2" />
            Add Filter
          </button>

          <div className="w-px h-6 bg-gray-200 mx-3" />

          <span className="text-sm text-gray-500">No filters applied</span>
        </div>

        <div className="relative">
          <table className="w-full text-left">
            <thead className="border-b border-gray-100">
              <tr className="text-xs text-gray-500 uppercase">
                <th className="py-2 px-2 w-52">Cardholder</th>
                <th className="py-2 px-2">Card</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg">
                <td className="py-4 px-2">
                  <div className="flex items-center gap-2">
                    <span>Nader kutub</span>
                    <span className="px-2 py-0.5 text-xs bg-blue-100 text-blue-900 rounded">
                      You
                    </span>
                  </div>
                </td>
                <td className="py-4 px-2">
                  <div className="flex items-center gap-2">
                    <LockIcon className="w-4 h-4 text-gray-400" />
                    <span>•••• •••• •••• 2020</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="absolute top-20">
            <WiseVirtualCard />
          </div>
        </div>
      </div>
    </div>
  );
}
