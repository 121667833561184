import React, { useEffect } from "react";
import { useCallback } from "react";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  GET_VIRTUAL_CARD,
  UPDATE_VIRTUAL_CARD,
} from "../../../queries/virtualCards";

import { useMutation } from "@apollo/client";
import VirtualCardFields from "./VirtualCardFields";

export function EditVirtualCard({ getAllVirtualCards }) {
  // const [errror, setError] = useState(null);
  const navigate = useNavigate();
  const { virtualCardId } = useParams();

  const [getVirtualCard, { loading, data: { virtualCard } = {} }] =
    useLazyQuery(GET_VIRTUAL_CARD, {
      variables: { virtualCardId: virtualCardId },
    });

  useEffect(() => {
    getVirtualCard();
  }, [virtualCardId]);

  const [updateVirtualCard, { loading: updateLoading }] = useMutation(
    UPDATE_VIRTUAL_CARD,
    {
      onCompleted: ({ updateVirtualCard: { success } }) => {
        if (success) {
          getAllVirtualCards();
          navigate(`/profile/cards/${virtualCardId}`);
        }
      },
    }
  );

  const onSubmit = useCallback((values) => {
    updateVirtualCard({
      variables: {
        input: {
          virtualCardId: values.id,
          cardName: values.cardName,
        },
      },
    });
  });

  if (!loading && !updateLoading) {
    const initialValues = virtualCard;

    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ errors, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="main-content__form">
              <VirtualCardFields title="Edit Card" errors={errors} />
            </form>
          );
        }}
      </Formik>
    );
  }

  return null;
}
